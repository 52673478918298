import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { LafvUserOqtSvc } from 'oqtane-api';
import { BehaviorSubject } from 'rxjs';
import { SubSink } from 'subsink';
import { OqtaneAuthService } from '../../core/oqtane-auth.service';
import { SnackbarService } from '../../service/snackbar.service';

interface FormGroupModel {
  currentPassword: FormControl<string>;
  newPassword: FormControl<string>;
  confirmPassword: FormControl<string>;
}
interface ModelDto {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
}
@Component({
  selector: 'app-change-password-dialog',
  templateUrl: './change-password-dialog.component.html',
  styleUrls: ['./change-password-dialog.component.scss'],
})
export class ChangePasswordDialogComponent implements OnInit, OnDestroy {
  subs = new SubSink();
  public saving$ = new BehaviorSubject<boolean>(false);
  form = this.fb.nonNullable.group<FormGroupModel>({} as FormGroupModel);

  options: FormlyFormOptions = {};
  fields!: FormlyFieldConfig[];
  model!: ModelDto;

  constructor(
    public dialogRef: MatDialogRef<any>,
    private fb: FormBuilder,
    private translate: TranslateService,
    private lafvUserOqtSvc: LafvUserOqtSvc,
    private translateService: TranslateService,
    private snackbar: SnackbarService,
    private oqtaneAuthService: OqtaneAuthService
  ) { }

  ngOnInit(): void {
    this.generateFormly();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  generateFormly() {
    this.fields = [
      {
        key: 'currentPassword',
        type: 'input',
        props: {
          label: _('SHARED.LABEL.CURRENT_PASSWORD'),
          translate: true,
          type: 'password',
          required: true,
        },
      },
      {
        key: 'newPassword',
        type: 'input',
        props: {
          label: _('SHARED.LABEL.PASSWORD'),
          translate: true,
          type: 'password',
          required: true,
        },

        validators: {
          password: {
            expression: (c: { value: string }) => {
              const password = c.value;
              const regex = /^(?=.*[A-Z])(?=.*\d).{8,}$/;
              return regex.test(password);
            },
            message: this.translate.stream(
              'SHARED.LABEL.PASSWORD_REQUIREMENTS'
            ),
          },
        },
      },
      {
        key: 'confirmPassword',
        type: 'input',
        props: {
          label: _('SHARED.LABEL.CONFIRM_PASSWORD'),
          translate: true,
          type: 'password',
          required: true,
        },

        validators: {
          confirmPassword: {
            expression: (control: {
              value: any;
              root: { value: { newPassword: string } };
            }) => {
              const value = control.value;
              const passwordValue = control.root.value.newPassword;
              return value === passwordValue ? { confirmPassword: true } : null;
            },
            message: this.translate.stream(
              'SHARED.LABEL.PASSWORDS_DO_NOT_MATCH'
            ),
          },
        },
        hooks: {
          onInit: (field: FormlyFieldConfig) => {
            const passwordField = field.form?.get('newPassword');
            if (passwordField) {
              passwordField.valueChanges.subscribe(() => {
                const confirmPasswordField = field.form?.get('confirmPassword');
                if (confirmPasswordField) {
                  confirmPasswordField.updateValueAndValidity();
                }
              });
            }
          },
        },
      },
    ];
  }

  submit() {

    if (this.saving$.value) return; // if already saving, return

    this.form.markAllAsTouched();
    if (this.form.invalid) return;

    this.saving$.next(true);
    this.subs.sink = this.lafvUserOqtSvc
      .apiLafvUserChangePasswordPost(
        this.form.value.currentPassword,
        this.form.value.newPassword
      )
      .subscribe(
        () => {
          this.snackbar.openSnackbar(
            this.translateService.instant(
              'ACCOUNT.CHANGE_PASSWORD.LABEL.SUCCESS'
            ),
            'bottom'
          );

          this.subs.sink = this.oqtaneAuthService
            .refreshAccessToken()
            .subscribe();
          this.dialogRef.close();
          this.saving$.next(false);
        },
        (error) => {
          this.saving$.next(false);
          this.snackbar.openSnackbar(
            this.translateService.instant(
              'ACCOUNT.CHANGE_PASSWORD.LABEL.ERROR'
            ),
            'bottom'
          );
          // console.error('Error Change Password:', error);
        }
      );
  }

  generateTranslateString() {
    _('ACCOUNT.CHANGE_PASSWORD.LABEL.ERROR');
    _('ACCOUNT.CHANGE_PASSWORD.LABEL.SUCCESS');
  }
}
