import { Component } from '@angular/core';
import { SharedIcons } from '../icons-const';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent {

  sharedIcons = SharedIcons;

}
