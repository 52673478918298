export const SUPER_USER = 'SuperUser';
export const LAFV_ADMIN = 'LafvAdmins';
export const LAFV_ORG_ADMIN = 'LafvOrgAdmins';
export const LAFV_FUND_ADMIN = 'LafvFundAdmins';
export const LAFV_FMA = 'LafvFma';
export const LAFV_PASSIVE_MEMBER = 'LafvPassiveMember';

export const DesiredRoles = [
  SUPER_USER,
  LAFV_ADMIN,
  LAFV_ORG_ADMIN,
  LAFV_FUND_ADMIN,
  LAFV_FMA,
  LAFV_PASSIVE_MEMBER
];
