<!-- Not in Use -->

<div class="button-container" *ngIf="field">
  <button
    mat-raised-button
    [color]="field.props?.['color']"
    [type]="field.props?.['type']"
    (click)="onClick($event)"
    [matTooltip]="field.props?.['toolTip'] | translate"
    [matTooltipPosition]="field.props?.['matTooltipPosition']"
  >
    {{ field.props?.['label'] }}
  </button>
</div>
