export const SharedIcons = {
  Home: 'home',
  Menu: 'menu',
  Expand_more: 'expand_more',
  ArrowRight: 'arrow_right',
  True: 'play_circle',
  False: 'stop_circle',
}

export const FundsIcons = {
  HeadLineSideNav: 'account_balance',
  FundsISIN: 'payments',
  FundsSingeSub: 'universal_currency_alt',
  Umbrella: 'umbrella',
  FstatusPreRegistration: 'info',
  FstatusInSubscription: 'play_circle',
  FstatusActive: 'check_circle',
  FstatusInLiquidation: 'join_inner',
  FstatusSuspended: 'highlight_off',
  FstatusLiquidated: 'stop_circle',
  FundSingle: 'trip_origin',
  FundSubfund: 'adjust',
  FundSubfundShare: 'pending',
  FundSingleShare: 'workspaces',
  FstatusDeleted: 'delete',
  Visibility: 'visibility',
  VisibilityOff: 'visibility_off',
};

export const DataAndDocumentsIcons = {
  HeadLineSideNav: 'database',
  Nav: 'chart_data',
  TerPtr: 'functions',
  Distribution: 'data_usage',
  DocumentsView: 'description',
  UploadHistory: 'cloud_done',
  UploadFailed: 'cancel',
  ValidationFailed: 'report',
  ValidationValid: 'verified',
  Imported: 'check_circle',
  IntegrationApi: 'public',
  WebUI: 'upload_file',
  QuartzJob: 'schedule',
};

export const MemberAreaIcons = {
  HeadLineSideNav: 'folder_special',
};

export const ExportIcons = {
  HeadLineSideNav: 'assignment_returned',
  FMA: 'download_for_offline',
  EFAMA: 'download_for_offline',
  FundExport: 'download_for_offline',
};

export const InvoiceIcons = {
  HeadLineSideNav: 'request_quote',
  Create: 'note_add',
  InvoiceList: 'format_list_bulleted',
  Settings: 'tune',
  Controlling: 'download_for_offline'
  // InvoicePdf: 'request_quote',
  // InvoiceWord: 'description',
};

export const SystemIcons = {
  HeadLineSideNav: 'settings_suggest',
  CurrencyRate: 'currency_exchange',
  Organization: 'groups',
  DropdownValues: 'share',
  DataHistory: 'history',
  User: 'manage_accounts',
};


export const UserIcons = {
  User: 'person',
  ManageUser: 'manage_accounts',
};



