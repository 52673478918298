<div class="card-container">
  <mat-card [routerLink]="cardData.route" ngxTippy [ngxTippy]="tippyTemplate" class="mb-3">
    <mat-card-content>
      <div>
        <mat-icon>{{ cardData.viewInfo[0].icon }}</mat-icon>
      </div>
      <div class="mat-card-content-count">
        <div class="d-flex">
          <ng-container *ngFor="let info of cardData.viewInfo; let last = last">
            <span *ngIf="info.count !== undefined">{{ info.count | number : '1.0-0' : 'de-CH' }}</span>
            <span *ngIf="info.dateData">{{ info.dateData | date : 'dd.MM.yyyy' }}</span>
            &nbsp;
            <span *ngIf="!last">/</span>
            &nbsp;
          </ng-container>
        </div>
      </div>
      <div class="mat-card-content-baseLine">
        <div class="d-flex">
          <ng-container *ngFor="let info of cardData.viewInfo; let last = last">
            <span>{{ info.name | translate }}</span>
            &nbsp;
            <span *ngIf="!last"> / </span>
            &nbsp;
          </ng-container>
        </div>
      </div>
    </mat-card-content>
    <div #tippyTemplate class="tippy-custom">
      <h3>{{ cardData.toolTipInfo.label | translate }}</h3>
      <p>{{ cardData.toolTipInfo.dateData | date : 'dd.MM.yyyy' }}</p>
    </div>
  </mat-card>
</div>
