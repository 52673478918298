import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { OqtaneAuthService } from './oqtane-auth.service';
import { UiPermissions } from '../models/UiPermissions';
import { AccountRouting } from '../shared/routing-const';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private oqtaneAuthService: OqtaneAuthService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return new Observable<boolean>((observer) => {
      // Check if the user has the required permission
      this.oqtaneAuthService.getPermissions().subscribe((permissions) => {
        // Get the required permission from the route data
        const requiredPermission = route.data[
          'permission'
        ] as keyof UiPermissions;

        // Check if the required permission is defined, and if so, whether the user has the permission
        if (!requiredPermission) {
          observer.next(true);
          observer.complete();
          return;
        }

        // Check if the user has the required permission
        const hasPermission = permissions[requiredPermission] === true;

        if (hasPermission) {
          // User has the required permission, allow access to the route
          observer.next(true);
        } else {
          // User does not have the required permission, deny access and show an alert
          observer.next(false);
          this.router.navigate(['/']);
        }

        observer.complete();
      });

      // Check if the user is logged in
      this.oqtaneAuthService.getUserInfo().subscribe((user) => {
        if (!user) {
          observer.next(false);
          if (state.url === '/')
            this.router.navigate([
              AccountRouting.BaseName,
              AccountRouting.Login,
            ]);
          else
            this.router.navigate(
              [AccountRouting.BaseName, AccountRouting.Login],
              { queryParams: { returnUrl: state.url } } // Get the redirect URL
            );
          observer.complete();
        }
      });
    });
  }
}
