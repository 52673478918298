<a (click)="onItemClick()" [routerLink]="item.link" routerLinkActive="active" matRipple class="app-menu-container">
  <mat-icon>{{ item.icon }}</mat-icon>
  <div class="link-text">
    <h3 style="margin: 0">
      {{ item.pageTitle | translate }}
    </h3>
  </div>
  <mat-icon *ngIf="item.child" [ngClass]="{ closed: !isOpenMenu, open: isOpenMenu, 'ml-auto': true }"> expand_more
  </mat-icon>
</a>

<!-- for the inner menu (child)  -->
<div class="nav" *ngIf="isOpenMenu">
  <app-app-menu-list-item *ngFor="let child of sideNavItemsChild$ | async" [item]="child"></app-app-menu-list-item>
</div>
