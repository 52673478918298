import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

export class TranslationAssetsInterceptor implements HttpInterceptor {
  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (!this.isTranslationAssetUrl(request)) return next.handle(request);

    // add version to the request
    request = request.clone({
      setParams: {
        v: environment.appVersion
      }
    });

    return next.handle(request).pipe()
  }

  private isTranslationAssetUrl(req: HttpRequest<unknown>): boolean {
    return (
      req.url.includes(`/assets/i18n/`)
    );
  }
}
