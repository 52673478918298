import { AfterViewInit, Component, OnDestroy, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { FieldTypeConfig } from '@ngx-formly/core';
import { FieldType } from '@ngx-formly/material';
import { FormlyFieldProps } from '@ngx-formly/material/form-field';
import { isObservable } from 'rxjs';
import { SubSink } from 'subsink';

interface InputProps extends FormlyFieldProps {
  maskConfig: {
    dropSpecialCharacters: boolean;
    mask: string;
    leadZero: boolean;
    allowNegativeNumbers: boolean;
  };
}

@Component({
  selector: 'app-formly-mask-input',
  templateUrl: './formly-mask-input.component.html',
  styleUrls: ['./formly-mask-input.component.scss'],
})
export class FormlyMaskInputComponent
  extends FieldType<FieldTypeConfig<InputProps>>
  implements OnDestroy, AfterViewInit
{
  private subs = new SubSink();

  private _maskIsObservable = false;
  private _mask: string = '';
  private _dropSpecialCharacters: boolean = true;
  private _leadZero: boolean = false;
  private _allowNegativeNumbers: boolean = false;

  get mask(): string {
    return this._maskIsObservable ? this._mask : this.props.maskConfig.mask;
  }

  get leadZero(): boolean {
    return this._maskIsObservable
      ? this._leadZero
      : this.props.maskConfig?.leadZero ?? false;
  }

  get allowNegativeNumbers(): boolean {
    return this._maskIsObservable
      ? this._allowNegativeNumbers
      : this.props.maskConfig?.allowNegativeNumbers ?? false;
  }

  get dropSpecialCharacters(): boolean {
    return this._maskIsObservable
      ? this._dropSpecialCharacters
      : this.props.maskConfig?.dropSpecialCharacters ?? true;
  }

  ngAfterViewInit(): void {
    if (this.field && this.field.props) {
      const maskConfig = this.field?.props.maskConfig;
      if (maskConfig && isObservable(maskConfig)) {
        this._maskIsObservable = true;
        this.subs.sink = maskConfig.subscribe((s) => {
          this._mask = (s as any).mask as string;
          this._dropSpecialCharacters = (s as any)
            .dropSpecialCharacters as boolean;

          this._leadZero = (s as any).leadZero as boolean;
          this._allowNegativeNumbers = (s as any)
            .allowNegativeNumbers as boolean;
        });
      }
    }
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this.subs != null) this.subs.unsubscribe();
  }

  get frmControl(): FormControl {
    return this.formControl as FormControl;
  }

  @ViewChild(MatInput) formFieldControl!: MatInput;
}
