import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private router: Router, private matSnackBar: MatSnackBar) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        // 500 Error
        // if (error.status === 500) {
        //   let errorMessage = 'HTTP 500 Internal Server Error';
        //   this.snackBarInfo(error, errorMessage);
        // }
        // 400 Error
        // TODO:: Open Error Handling
        // if (error.status === 400) {
        //   let errorMessage = 'HTTP 400 Bad Request';
        //   this.snackBarInfo(error, errorMessage);
        // }
        // // 401 Error
        // if (error.status === 401) {
        //   let errorMessage = 'HTTP 401 Authentication failed';
        //   this.snackBarInfo(error, errorMessage);
        // }
        // // 403 Error
        // if (error.status === 403) {
        //   let errorMessage = 'HTTP 403 Forbidden no right';
        //   this.snackBarInfo(error, errorMessage);
        // }
        // // 404 Error, Route to NotFoundPage
        // if (error.status === 404) {
        //   let errorMessage = 'HTTP 404 Not Found';
        //   this.snackBarInfo(error, errorMessage);
        //   // Not Found Page (404)
        //   this.router.navigate(['/error404']);
        // }
        return throwError(error);
      })
    );
  }

  snackBarInfo(error: HttpErrorResponse, errorMessage: string) {
    if (error.error && error.error.detail) {
      this.matSnackBar.open((errorMessage += '\n' + error.error.detail), 'OK', {
        duration: 5000,
        verticalPosition: 'top',
      });
    } else {
      this.matSnackBar.open(
        errorMessage + '-No specific error message available',
        'OK',
        {
          duration: 5000,
          verticalPosition: 'top',
        }
      );
    }
  }
}
