import { SelectionModel } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, OnDestroy, OnInit, Type } from '@angular/core';
import {
  MatTreeFlatDataSource,
  MatTreeFlattener,
} from '@angular/material/tree';
import {
  FieldType,
  FieldTypeConfig,
  FormlyFieldConfig,
  FormlyFieldProps,
} from '@ngx-formly/core';
import * as _ from 'lodash';
import {
  BehaviorSubject,
  Observable,
  isObservable,
  map,
  switchMap,
  take
} from 'rxjs';
import { SubSink } from 'subsink';
import { FundGroupedByHeader } from '../../../doc/doc-upload/doc-upload-data.service';

export interface FundNode {
  id: number;
  name: string;
  selectable: boolean;
  children?: FundNode[];
  isin: string;
}

interface FlatNode {
  expandable: boolean;
  name: string;
  level: number;
  selectable: boolean;
  id: number;
  isin: string;
}

interface FundSelectTreeProps extends FormlyFieldProps {
  treeData?: FundGroupedByHeader[] | Observable<FundGroupedByHeader[]>;
  resultDisplayMode?: 'all' | 'first';
}

export interface FormlyFundSelectTreeFieldConfig
  extends FormlyFieldConfig<FundSelectTreeProps> {
  type: 'fund-select-tree' | Type<FormlyFundSelectTreeComponent>;
}

@Component({
  selector: 'app-formly-fund-select-tree',
  templateUrl: './formly-fund-select-tree.component.html',
  styleUrls: ['./formly-fund-select-tree.component.scss'],
})
export class FormlyFundSelectTreeComponent
  extends FieldType<FieldTypeConfig<FundSelectTreeProps>>
  implements OnInit, OnDestroy {
  private subs = new SubSink();

  private _transformer = (node: FundNode, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      level: level,
      id: node.id,
      selectable: node.selectable,
      isin: node.isin,
    };
  };

  treeControl = new FlatTreeControl<FlatNode>(
    (node) => node.level,
    (node) => node.expandable
  );

  treeFlattener = new MatTreeFlattener(
    this._transformer,
    (node) => node.level,
    (node) => node.expandable,
    (node) => node.children
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  fundSelectionModel = new SelectionModel<FundNode>(true);
  selectedValues$ = new BehaviorSubject<FundNode[]>([]);

  ngOnDestroy(): void {
    if (this.subs) this.subs.unsubscribe();
  }

  ngOnInit(): void {
    if (this.field.props?.treeData && isObservable(this.field.props.treeData)) {
      this.subs.sink = this.field.props.treeData
        .pipe(
          map((m) => {
            return m.flatMap((fundHeader) => {
              return {
                id: fundHeader.id,
                name: fundHeader.name,
                selectable: false,
                children: fundHeader.funds?.map((fund) => {
                  return {
                    id: fund.id,
                    name: fund.fullName,
                    isin: fund.isin,
                    selectable: true,
                  } as FundNode;
                }),
              } as FundNode;
            });
          })
        )
        .subscribe(s => {
          this.dataSource.data = s
          this.selectedValues$.next([])
          this.fundSelectionModel.clear()
        });
    } else {
      if (
        this.field.props?.treeData &&
        !isObservable(this.field.props.treeData)
      ) {
        this.dataSource.data = this.field.props.treeData.flatMap(
          (fundHeader) => {
            return {
              id: fundHeader.id,
              name: fundHeader.name,
              selectable: false,
              children: fundHeader.funds?.map((fund) => {
                return {
                  id: fund.id,
                  name: fund.fullName,
                  isin: fund.isin,
                  selectable: true,
                } as FundNode;
              }),
            } as FundNode;
          }
        );
      }
    }

    this.subs.sink = this.fundSelectionModel.changed
      .pipe(
        switchMap((selection) => {
          return this.selectedValues$.pipe(
            take(1),
            map((m) => {
              return {
                changes: selection,
                values: m,
              };
            })
          );
        })
      )
      .subscribe((s) => {
        if (s.changes?.added && s.changes.added.length > 0) {
          s.values.push(...s.changes.added);
        }

        if (s?.changes.removed && s.changes.removed.length > 0) {
          _.pull(s.values, ...s.changes.removed);
        }

        this.selectedValues$.next(s.values);
      });

    this.subs.sink = this.selectedValues$.subscribe((s) => {
      this.formControl.setValue(s);
    });


    // handle value-reset to undefined
    this.subs.sink = this.formControl.valueChanges.subscribe(s => {
      if (s === undefined) {
        this.fundSelectionModel.clear()
        this.selectedValues$.next([])
      }
    })
  }

  nodeSelectionToggle(node: FundNode): void {
    this.fundSelectionModel.toggle(node);
  }

  hasChild = (_: number, node: FlatNode) => node.expandable;
}
