import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { IExceptionTelemetry } from '@microsoft/applicationinsights-web';
import jwt_decode, { JwtPayload } from 'jwt-decode';
import { Configuration } from 'lafv-api';
import {
  LafvAuthOqtSvc,
  Configuration as OqtConfiguration,
  OqtaneModelsUserDto,
  UserOqtSvc,
} from 'oqtane-api';
import {
  BehaviorSubject,
  Observable,
  catchError,
  map,
  of,
  switchMap,
  tap,
} from 'rxjs';
import { InsightsService } from '../insights/insights.service';
import { UiPermissions } from '../models/UiPermissions';
import { JwtPayloadEnhanced } from '../models/interface';
import { AccountRouting } from '../shared/routing-const';
import { LAFV_FMA, LAFV_ORG_ADMIN } from '../shared/userRole-const';
import { defaultPermissionPerRole } from './permission';

@Injectable({
  providedIn: 'root',
})
export class OqtaneAuthService {
  private accessToken = new BehaviorSubject<string | null>('');
  defaultPermissionPerRole = defaultPermissionPerRole;

  getAccessToken(): Observable<string | null> {
    return this.accessToken;
  }

  constructor(
    private insights: InsightsService,
    private apiConfiguration: Configuration,
    private oqtConfiguration: OqtConfiguration,
    private lafvAuthOqtSvc: LafvAuthOqtSvc,
    private userOqtSvc: UserOqtSvc,
    private router: Router
  ) { }

  logout(model: OqtaneModelsUserDto): Observable<any> {
    return this.userOqtSvc.apiUserLogoutPost(model).pipe(
      switchMap((result) => {
        return this.refreshAccessToken().pipe(map(() => result));
      }),
      tap((t) => {
        this.clearAccessToken();
        this.router.navigate([AccountRouting.BaseName, AccountRouting.Login]);
      })
    );
  }

  private setAccessToken(t: string): void {
    this.accessToken.next(t);
    this.apiConfiguration.credentials['Bearer'] = t; // used by lafv-api
    this.oqtConfiguration.credentials['Bearer'] = t; // used by oqtane-api
  }

  private clearAccessToken(): void {
    this.accessToken.next('');
    this.apiConfiguration.credentials['Bearer'] = ''; // used by lafv-api
    this.oqtConfiguration.credentials['Bearer'] = ''; // used by oqtane-api
  }

  login(model: OqtaneModelsUserDto): Observable<string> {
    return this.userOqtSvc
      .apiUserLoginPost(
        true, // setCookie
        true, // isPersistent
        model
      )
      .pipe(
        switchMap((result) => {
          if (!result || result.userId == 0) return of(null);
          return this.refreshAccessToken().pipe(map(() => result));
        }),
        map((m) => {
          if (m?.deletedOn) return 'userDeleted';
          if (m) return 'true';
          return 'false';
        })
      );
  }

  refreshAccessToken(): Observable<string> {
    return this.lafvAuthOqtSvc.apiLafvAuthTokenGet().pipe(
      tap((t) => {
        this.setAccessToken(t);
      }),
      catchError((e) => {
        this.insights.trackException({
          exception: e,
        } as IExceptionTelemetry);
        return of('');
      })
    );
  }

  getUserInfo(): Observable<JwtPayload | null> {
    return this.accessToken.pipe(
      map((token) => {
        if (!token) return null;
        let payload = jwt_decode(token) as JwtPayloadEnhanced;

        // Remove the 'Registered Users' role from the payload, this is an Oqtane default role
        if (payload.role) {
          payload.role = payload.role.filter(r => r !== 'Registered Users');
        }

        return payload;
      })
    );
  }

  getPermissions(): Observable<UiPermissions> {
    return this.getUserInfo().pipe(
      map((p: JwtPayload | null) => p as JwtPayloadEnhanced),
      map((jwtp) => {
        const roles = jwtp.role;

        let mergedPermissions: UiPermissions | undefined;
        // Special case for FMA, role (LafvFmaOrgAdmin) only give in Frontend
        if (roles.includes(LAFV_FMA) && roles.includes(LAFV_ORG_ADMIN)) {
          // FMA User with Org Admin only see Export and System > User Management
          return (
            this.getPermissionsForRole('LafvFmaOrgAdmin') ||
            ({} as UiPermissions)
          );
        }

        roles.forEach((role: string) => {
          const permissions = this.getPermissionsForRole(role);
          if (permissions) {
            // Merge the permissions for each role
            mergedPermissions = this.mergePermissions(
              mergedPermissions,
              permissions
            );
          }
        });
        return mergedPermissions || ({} as UiPermissions);
      })
    );
  }

  getPermissionsForRole(role: string): UiPermissions | undefined {
    // Check if the given role exists in the defaultPermissionPerRole constant
    if (this.defaultPermissionPerRole.hasOwnProperty(role)) {
      // If the role is found, return the corresponding permissions
      return this.defaultPermissionPerRole[role];
    } else {
      // If the role is not found, return undefined or a default value
      return undefined;
    }
  }

  mergePermissions(
    permissionsA: UiPermissions | undefined,
    permissionsB: UiPermissions
  ): UiPermissions {
    if (!permissionsA) {
      // If permissionsA is undefined, return permissionsB directly
      return permissionsB;
    }
    // Merge the permissions by copying the properties from permissionsB to permissionsA,
    // but only if permissionsB has a true value and permissionsA does not have a true value for that property
    const mergedPermissions: any = { ...permissionsA };
    // const mergedPermissions: UiPermissions = { ...permissionsA };
    for (const [key, value] of Object.entries(permissionsB)) {
      if (value && !mergedPermissions[key]) {
        mergedPermissions[key] = value;
      }
    }
    return mergedPermissions;
  }
}
