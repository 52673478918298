import { MediaMatcher } from '@angular/cdk/layout';
import { Component, HostBinding, OnInit, ViewChild } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { MatSidenav } from '@angular/material/sidenav';
import {
  ActivatedRoute,
  NavigationEnd,
  Router
} from '@angular/router';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { TranslateService } from '@ngx-translate/core';
import { Dayjs } from 'dayjs';
import 'dayjs/locale/de';
import 'dayjs/locale/de-ch';
import 'dayjs/locale/en';
import 'dayjs/locale/en-gb';
import { PingLafvSvc } from 'lafv-api';
import { LocalStorageService } from 'ngx-localstorage';
import { Observable, combineLatest, filter, map } from 'rxjs';
import { environment } from '../environments/environment';
import { ChangePasswordDialogComponent } from './account/change-password-dialog/change-password-dialog.component';
import { OqtaneAuthService } from './core/oqtane-auth.service';
import { PreviousRouteService } from './core/previous-route.service';
import { LogEvent, LogTrace } from './insights/insights.decorator';
import { InsightsService } from './insights/insights.service';
import { UiPermissions } from './models/UiPermissions';
import { items } from './navigation-item';
import { SharedIcons } from './shared/icons-const';
import { AccountRouting, FundsRouting } from './shared/routing-const';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  @HostBinding('attr.app-version') appVersionAttr = environment.appVersion;

  env = environment;

  fundsRouting = FundsRouting;
  accountRouting = AccountRouting;
  isIconClick: boolean = false;
  currentUrl: string = '';
  sharedIcons = SharedIcons;

  sideNavItems$ = this.oqtaneAuthService.getPermissions().pipe(
    map((permissions: UiPermissions | any) => {
      var visibleItems = items.filter((f) => {
        if (!f.permission) return true;
        return permissions[f.permission];
      });

      return visibleItems;
    })
  );

  userInfo$: Observable<any> = this.oqtaneAuthService.getUserInfo();

  smallScreen: MediaQueryList = this.media.matchMedia('(max-width: 1000px)');
  @ViewChild('sidenav') sidenav!: MatSidenav;
  sideNavOpened = !this.smallScreen.matches;

  currentLanguage: string = '';

  backendEnvironment$ = combineLatest([
    this.userInfo$,
    this.pingLafvSvc.ping(),
  ]).pipe(
    map(([userInfo, m]) => {
      let backgroundColor = '';
      let fontColor = '';
      let showInfoBar = false;

      switch (m.environmentName) {
        case 'Local': {
          backgroundColor = 'purple';
          fontColor = 'white';
          showInfoBar = true;
          break;
        }
        case 'Development': {
          backgroundColor = 'red';
          fontColor = 'white';
          showInfoBar = true;
          break;
        }
        case 'Sandbox': {
          backgroundColor = 'gold';
          fontColor = 'black';
          showInfoBar = true;
          break;
        }
        case 'Production': {
          backgroundColor = 'green';
          fontColor = 'white';
          showInfoBar = true;
          break;
        }
        default: {
          backgroundColor = 'magenta';
          fontColor = 'black';
          showInfoBar = true;
          break;
        }
      }

      return {
        environmentName: m.environmentName,
        databaseName: m.databaseName,
        backgroundColor: backgroundColor,
        fontColor: fontColor,
        showInfoBar: showInfoBar,
        backendVersion: m.version,
        frontendVersion: environment.appVersion,
      };
    })
  );

  constructor(
    // is needed for the dialogs, to close correctly, do not delete 2dg
    private previousRouteService: PreviousRouteService,
    //
    private translate: TranslateService,
    private insights: InsightsService,
    private oqtaneAuthService: OqtaneAuthService,
    private pingLafvSvc: PingLafvSvc,
    private dateAdapter: DateAdapter<Dayjs>,
    private media: MediaMatcher,
    localStorageService: LocalStorageService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    private matIconReg: MatIconRegistry
  ) {
    translate.addLangs(['en', 'de']);
    translate.setDefaultLang('en');
    this.dateAdapter.setLocale('en');

    const phraseAppTranslations = localStorageService.get<boolean>(
      'phrase-app-translations'
    );

    let config = {
      projectId: '60770efd1eda0a43c8af054e445fa894',
      accountId: 'ad34ee98e83029dc1b4b6c9a58334e32',
      phraseEnabled: phraseAppTranslations ?? false,
      prefix: '{{__',
      suffix: '__}}',
      fullReparse: true,
      autoLowercase: false,
      useOldICE: false,
    };

    // 2md @ 06.12.2023 disabled phrase app editor, not needed atm
    // initializePhraseAppEditor(config);
    // setTimeout(() => {
    //   var sheet = new CSSStyleSheet();
    //   sheet.replaceSync(`.syn_btn  { opacity: 0.5 !important; }`);

    //   var ice = window.document.getElementById('ice-shadow-root-container');

    //   if (ice && ice.shadowRoot) {
    //     ice.shadowRoot.adoptedStyleSheets.push(sheet);
    //   }
    // }, 2000);

    // sample for a trace message in a constructor
    // decorator is not working here, class decorator not possible because it would run before injection (no insights service available)
    this.insights.trackTrace({
      message: 'AppComponent.ctor()',
      severityLevel: SeverityLevel.Information,
    });

    this.router.events
      .pipe(
        filter(
          (event): event is NavigationEnd => event instanceof NavigationEnd
        )
      )
      .subscribe((event: NavigationEnd) => {
        this.currentUrl = event.urlAfterRedirects;
      });
  }

  @LogTrace()
  ngOnInit(): void {
    this.currentLanguage = localStorage.getItem('currentLanguage')
      ? localStorage.getItem('currentLanguage')!
      : this.translate.getDefaultLang();

    this.useLanguage(this.currentLanguage);

    this.smallScreen.addEventListener(
      'change',
      (c) => (
        (this.sidenav.opened = !c.matches),
        (this.sidenav.mode = c.matches ? 'over' : 'side')
      )
    );
    this.matIconReg.setDefaultFontSetClass('material-symbols-outlined');
  }

  // sample for a custom event message on a method
  @LogEvent({
    name: 'ChangeLanguage',
  })
  // sample for a trace message with custom severity level; also shows that you can use the decorator multiple times on the same method
  @LogTrace({ severityLevel: SeverityLevel.Information })
  useLanguage(language: string): void {
    this.translate.use(language);
    this.dateAdapter.setLocale(language);
    window.localStorage.setItem('currentLanguage', language);
    this.currentLanguage = language;
  }

  openDialogChangePassword() {
    this.dialog.open(ChangePasswordDialogComponent, {
      width: '400px',
    });
  }

  generateTranslateString() {
    _('SHARED.LABEL.VALIDATION_REQUIRED');
    _('SHARED.LABEL.NO_CHOICE');
  }
}
