import * as _ from 'lodash';
import { InsightsModule } from './insights.module';
import { inspect } from 'util';
import { diff_match_patch } from 'diff-match-patch';
import { SeverityLevel } from '@microsoft/applicationinsights-web';

interface TraceParams {
  severityLevel?: SeverityLevel;
  includeArgs?: boolean;
  includeResult?: boolean;
  includeObjectState?: boolean;
  objectStateDiff?: boolean | 'html' | 'txt';
  data?: {
    [key: string]: any;
  };
}

const defaultTraceParams: Required<TraceParams> = {
  severityLevel: SeverityLevel.Verbose,
  includeArgs: true,
  includeResult: true,
  includeObjectState: false,
  objectStateDiff: false,
  data: {},
};

// doesn't work, because the constructor is called before the module is initialized
// export function LogTraceClass(constructor: Function) {
//     const service = InsightsModule.insights;
//     service.trackTrace({
//         message: `${constructor.name}.ctor()`,
//     });
// }

export function LogTrace(params?: TraceParams) {
  const options: Required<TraceParams> = {
    severityLevel:
      params?.severityLevel === undefined
        ? defaultTraceParams.severityLevel
        : params.severityLevel,

    includeArgs:
      params?.includeArgs === undefined
        ? defaultTraceParams.includeArgs
        : params.includeArgs,

    includeResult:
      params?.includeResult === undefined
        ? defaultTraceParams.includeResult
        : params.includeResult,

    includeObjectState:
      params?.includeObjectState === undefined
        ? defaultTraceParams.includeObjectState
        : params.includeObjectState,

    objectStateDiff:
      params?.objectStateDiff === undefined
        ? defaultTraceParams.objectStateDiff
        : params.objectStateDiff,
    data: params?.data === undefined ? defaultTraceParams.data : params.data,
  };

  return (
    target: Object,
    propertyKey: string,
    descriptor: PropertyDescriptor
  ) => {
    // logic for a accessor (setter)
    if (descriptor.set !== undefined) {
      const originalSetter = descriptor.set;

      descriptor.set = function (...args) {
        const properties: {
          [key: string]: any;
        } = {};
        const service = InsightsModule.insights;
        let result: any = undefined;
        let error: any = undefined;

        if (options.includeArgs && _.isArray(args) && args.length > 0)
          // arguments are in calling order, found no way* to get the parameter names
          // (* most solutions prase the function body and look for the parameter names which is error prone, also minimization will break it)
          // https://stackoverflow.com/questions/1007981/how-to-get-function-parameter-names-values-dynamically-from-javascript
          properties['arguments'] = args;

        if (options.data) properties['traceData'] = options.data;

        try {
          originalSetter.apply(this, args);
          error = null;
        } catch (e) {
          error = e;
          throw e;
        } finally {
          if (error) {
            options.severityLevel = SeverityLevel.Error;
            properties['error'] = error;
          }

          service.trackTrace({
            severityLevel: options.severityLevel,
            message: `${target.constructor.name}.${propertyKey} - set`,
            properties: Object.assign({}, properties),
          });
        }

        return result;
      };
    }

    // logic for a accessor (getter)
    if (descriptor.get !== undefined) {
      const originalGetter = descriptor.get;

      descriptor.get = function () {
        const properties: {
          [key: string]: any;
        } = {};
        const service = InsightsModule.insights;
        let result: any = undefined;
        let error: any = undefined;

        if (options.data) properties['traceData'] = options.data;

        try {
          result = originalGetter.apply(this);
          error = null;
        } catch (e) {
          error = e;
          throw e;
        } finally {
          if (options.includeResult) properties['result'] = result;

          if (error) {
            options.severityLevel = SeverityLevel.Error;
            properties['error'] = error;
          }

          service.trackTrace({
            severityLevel: options.severityLevel,
            message: `${target.constructor.name}.${propertyKey} - get`,
            properties: Object.assign({}, properties),
          });
        }

        return result;
      };
    }

    // logic for a method
    if (descriptor.value !== undefined) {
      const originalMethod = descriptor.value;

      descriptor.value = async function (...args: any) {
        const properties: {
          [key: string]: any;
        } = {};

        const service = InsightsModule.insights;
        let result: any = undefined;
        let error: any = undefined;

        let state: {
          before: any;
          after: any;
        } = { before: undefined, after: undefined };

        if (options.data) properties['traceData'] = options.data;
        if (options.includeArgs && _.isArray(args) && args.length > 0)
          // arguments are in calling order, found no way* to get the parameter names
          // (* most solutions prase the function body and look for the parameter names which is error prone, also minimization will break it)
          // https://stackoverflow.com/questions/1007981/how-to-get-function-parameter-names-values-dynamically-from-javascript
          properties['arguments'] = args;

        /**
         * object is converted to string via util.inspect
         * no drill down support in insights portal yet its the best way to have a somewhat readable object
         */
        if (options.includeObjectState) {
          state.before = inspect(this);
          properties['state'] = Object.assign({}, state);
        }

        service.trackTrace({
          severityLevel: options.severityLevel,
          message: `${target.constructor.name}.${propertyKey}() - enter`,
          properties: Object.assign({}, properties),
        });

        try {
          result = originalMethod.apply(this, args);
          error = null;
        } catch (e) {
          error = e;
          throw e;
        } finally {
          if (options.includeResult) properties['result'] = result;

          if (error) {
            options.severityLevel = SeverityLevel.Error;
            properties['error'] = error;
          }

          if (options.includeObjectState) {
            state.after = inspect(this);

            if (options.objectStateDiff !== false) {
              const dmp = new diff_match_patch();
              const diff = dmp.diff_main(state.before, state.after);
              dmp.diff_cleanupSemantic(diff);

              switch (options.objectStateDiff) {
                case 'html':
                  const htmlDiff = dmp.diff_prettyHtml(diff);
                  (state as any)['diffHtml'] = htmlDiff;
                  break;
                case 'txt':
                default:
                  const patches = dmp.patch_make(diff);
                  const txtDiff = dmp.patch_toText(patches);
                  (state as any)['diffTxt'] = txtDiff;
                  break;
              }
            }

            properties['state'] = Object.assign({}, state);
          }

          service.trackTrace({
            severityLevel: options.severityLevel,
            message: `${target.constructor.name}.${propertyKey}() - return`,
            properties: Object.assign({}, properties),
          });
        }

        return result;
      };
    }

    return descriptor;
  };
}

interface EventParams {
  logArgs?: boolean;
  name?: string;
  properties?: {
    [key: string]: any;
  };
}

const defaultEventParams: Required<EventParams> = {
  logArgs: true,
  name: '',
  properties: {},
};

export function LogEvent(params?: EventParams) {
  const options: Required<EventParams> = {
    logArgs:
      params?.logArgs === undefined
        ? defaultTraceParams.includeArgs
        : params.logArgs,
    name: params?.name === undefined ? defaultEventParams.name : params.name,
    properties:
      params?.properties === undefined
        ? defaultEventParams.properties
        : params.properties,
  };

  return (
    target: Object,
    propertyKey: string,
    descriptor: PropertyDescriptor
  ) => {
    const originalMethod = descriptor.value;

    descriptor.value = async function (...args: any) {
      const service = InsightsModule.insights;

      const properties: {
        [key: string]: any;
      } = {};

      if (options.logArgs && _.isArray(args) && args.length > 0)
        properties['arguments'] = args;

      service.trackEvent({
        name: options.name || `${target.constructor.name}.${propertyKey}()`,
        properties: { ...properties, ...options.properties },
      });

      const result = originalMethod.apply(this, args);
      return result;
    };

    return descriptor;
  };
}
