import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';

import { FormlyModule } from '@ngx-formly/core';
import {
  TranslateCompiler,
  TranslateLoader,
  TranslateModule,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeDeCh from '@angular/common/locales/de-CH';
import {
  Configuration as LafvConfiguration,
  ConfigurationParameters as LafvConfigurationParameters,
  LafvApiModule,
} from 'lafv-api';

import {
  Configuration as OqtConfiguration,
  ConfigurationParameters as OqtConfigurationParameters,
  OqtApiModule,
} from 'oqtane-api';

// Components
import { HomeComponent } from './home/home.component';
import { AppComponent } from './app.component';
import { AppMenuListItemComponent } from './app-menu-list-item/app-menu-list-item.component';
import { SharedModule } from './shared/shared.module';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { environment } from '../environments/environment';
import { InsightsModule } from './insights/insights.module';
import { MatNativeDateModule } from '@angular/material/core';
import { CoreModule } from './core/core.module';
import { FundListComponent } from './fund/fund-list/fund-list.component';
import { CustomPaginatorIntl } from './core/custom-paginator';
import { PageNotFoundComponent } from './core/page-not-found/page-not-found.component';
import { ErrorInterceptor } from './core/error.interceptor';
import { PhraseAppCompiler } from 'ngx-translate-phraseapp';
import { LocalStorageDirective } from 'ngx-localstorage';
import { NgxSpinner, NgxSpinnerModule } from 'ngx-spinner';
import { CardInfoComponent } from './home/card-info/card-info.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    AppMenuListItemComponent,
    FundListComponent,
    HomeComponent,
    CardInfoComponent,
    PageNotFoundComponent,
  ],
  imports: [
    SharedModule,
    CoreModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    InsightsModule,
    BrowserModule,
    FormsModule,
    MatNativeDateModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      compiler: {
        provide: TranslateCompiler,
        useClass: PhraseAppCompiler,
      },
    }),
    FormlyModule.forRoot(),
    LafvApiModule.forRoot(lafvApiConfigFactory),
    OqtApiModule.forRoot(oqtApiConfigFactory),
    NgxSpinnerModule.forRoot({ type: 'ball-scale-multiple' }),
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'de-CH' },
    { provide: MatPaginatorIntl, useClass: CustomPaginatorIntl },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {
    registerLocaleData(localeDeCh);
  }
}

export function lafvApiConfigFactory(): LafvConfiguration {
  if (
    !environment.lafvApiUrl.startsWith('http://') &&
    !environment.lafvApiUrl.startsWith('https://')
  ) {
    if (!environment.lafvApiUrl.startsWith('/')) {
      environment.lafvApiUrl = `/${environment.lafvApiUrl}`;
    }
    environment.lafvApiUrl = `${location.protocol}//${location.host}${environment.lafvApiUrl}`;
  }
  if (environment.lafvApiUrl.endsWith('/')) {
    environment.lafvApiUrl = environment.lafvApiUrl.substring(
      0,
      environment.lafvApiUrl.length - 1
    );
  }

  const params: LafvConfigurationParameters = {
    basePath: environment.lafvApiUrl,
    withCredentials: false,
  };

  return new LafvConfiguration(params);
}

export function oqtApiConfigFactory(): OqtConfiguration {
  if (
    !environment.oqtaneApiUrl.startsWith('http://') &&
    !environment.oqtaneApiUrl.startsWith('https://')
  ) {
    if (!environment.oqtaneApiUrl.startsWith('/')) {
      environment.oqtaneApiUrl = `/${environment.oqtaneApiUrl}`;
    }
    environment.oqtaneApiUrl = `${location.protocol}//${location.host}${environment.oqtaneApiUrl}`;
  }
  if (environment.oqtaneApiUrl.endsWith('/')) {
    environment.oqtaneApiUrl = environment.oqtaneApiUrl.substring(
      0,
      environment.oqtaneApiUrl.length - 1
    );
  }

  const params: OqtConfigurationParameters = {
    basePath: environment.oqtaneApiUrl,
    withCredentials: false,
  };

  return new OqtConfiguration(params);
}
