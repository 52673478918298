import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { FormlyMatDatepickerModule } from '@ngx-formly/material/datepicker';
import { FormlyMatToggleModule } from '@ngx-formly/material/toggle';
import { AgGridModule } from 'ag-grid-angular';

import { FormsModule } from '@angular/forms';

import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { GoogleChartsModule } from 'angular-google-charts';
import { NgxTippyModule } from 'ngx-tippy-wrapper';
import { BreadcrumbModule } from 'xng-breadcrumb';
import { MaterialModule } from '../material.module';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { FormlyExtensionModule } from './formly-extension/formly-extension.module';

import { TableVirtualScrollModule } from 'ng-table-virtual-scroll';
import { NgxFileDropModule } from 'ngx-file-drop';
import {
  IConfig,
  NgxMaskDirective,
  NgxMaskPipe,
  provideNgxMask,
} from 'ngx-mask';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MAT_DAYJS_DATE_ADAPTER_OPTIONS } from '../core/dayjs-date-adapter';
import { MatDayjsDateModule } from '../core/material-dayjs.adapter.module';
import { AddEditFundFormlyComponent } from './add-edit-fundheader-fund-dialog/add-edit-fund-formly/add-edit-fund-formly.component';
import { AddEditFundheaderFormlyComponent } from './add-edit-fundheader-fund-dialog/add-edit-fundheader-formly/add-edit-fundheader-formly.component';
import { ButtonCellRenderComponent } from './cell-render-ag-grid/button-cell-render.component';
import { ButtonDownloadInvoiceCellRenderComponent } from './cell-render-ag-grid/button-download-invoice-cell-render.component';
import { DateCellRenderComponent } from './cell-render-ag-grid/date-cell-render.component';
import { DateTimeCellRenderComponent } from './cell-render-ag-grid/date-time-cell-render.component';
import { IconCellRenderComponent } from './cell-render-ag-grid/icon-cell-render.component';
import { LinkCellRenderComponent } from './cell-render-ag-grid/link-cell-render.component';
import { NumberCellRenderComponent } from './cell-render-ag-grid/number-cell-render.component';
import { ShortcutCellRenderComponent } from './cell-render-ag-grid/shortcut-cell-render.component';
import { TranslateArrayPipeCellRenderComponent } from './cell-render-ag-grid/translate-array-pipe-cell-render.component';
import { TranslateChoiceCellRenderComponent } from './cell-render-ag-grid/translate-choice-cell-render.component';
import { TranslatePipeCellRenderComponent } from './cell-render-ag-grid/translate-pipe-cell-render.component';
import { DialogWarningDeleteComponent } from './dialog-warning-delete/dialog-warning-delete.component';
import { IconHeaderRenderComponent } from './header-renderer-ag-grid/icon-header-render.component';
import { ToolTipHeaderRenderComponent } from './header-renderer-ag-grid/tooltip-header-render.component';
import { FileDropComponent } from './nav-ter-dist-upload-stepper/file-drop/file-drop.component';
import { ValidationResultTableComponent } from './nav-ter-dist-upload-stepper/validation-result-table/validation-result-table.component';
import { PhraseAppModeComponent } from './phrase-app-mode/phrase-app-mode.component';

const maskConfig: Partial<IConfig> = {
  validation: false,
  decimalMarker: '.',
  thousandSeparator: "'",
};

@NgModule({
  declarations: [
    BreadcrumbComponent,
    DateCellRenderComponent,
    DateTimeCellRenderComponent,
    NumberCellRenderComponent,
    IconCellRenderComponent,
    ShortcutCellRenderComponent,
    TranslatePipeCellRenderComponent,
    DialogWarningDeleteComponent,
    LinkCellRenderComponent,
    TranslateChoiceCellRenderComponent,
    ButtonCellRenderComponent,
    ButtonDownloadInvoiceCellRenderComponent,
    IconHeaderRenderComponent,
    ToolTipHeaderRenderComponent,
    AddEditFundFormlyComponent,
    AddEditFundheaderFormlyComponent,
    FileDropComponent,
    ValidationResultTableComponent,
    PhraseAppModeComponent,
    TranslateArrayPipeCellRenderComponent
  ],
  imports: [
    CommonModule,
    AgGridModule,
    FormlyModule,
    FormlyMaterialModule,
    FormlyMatDatepickerModule,
    FormlyMatToggleModule,
    NgxTippyModule,
    BreadcrumbModule,
    TranslateModule,
    MaterialModule,
    MatDayjsDateModule,
    GoogleChartsModule,
    ReactiveFormsModule,
    FormlyExtensionModule,
    FormsModule,
    TableVirtualScrollModule,
    NgxFileDropModule,
    NgxSpinnerModule,
    NgxMaskDirective,
    NgxMaskPipe,
  ],
  exports: [
    CommonModule,
    AgGridModule,
    FormlyModule,
    FormlyMaterialModule,
    FormlyMatDatepickerModule,
    FormlyMatToggleModule,
    NgxTippyModule,
    BreadcrumbModule,
    TranslateModule,
    MaterialModule,
    GoogleChartsModule,
    ReactiveFormsModule,
    BreadcrumbComponent,
    FormlyExtensionModule,
    FormsModule,
    TableVirtualScrollModule,
    NgxFileDropModule,
    NgxSpinnerModule,
    FileDropComponent,
    ValidationResultTableComponent,
    PhraseAppModeComponent,
    NgxMaskDirective,
    NgxMaskPipe,
  ],
  providers: [
    // { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    { provide: MAT_DAYJS_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    provideNgxMask(maskConfig),
    DatePipe,
  ],
})
export class SharedModule { }
