import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FORMLY_CONFIG, FormlyModule } from '@ngx-formly/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NgxMaskDirective, NgxMaskPipe } from 'ngx-mask';
import { MaterialModule } from '../../material.module';
import { FormlyButtonComponent } from './formly-button/formly-button.component';
import { FormlyFileUploadComponent } from './formly-file-upload/formly-file-upload.component';
import { FormlyFundSelectTreeComponent } from './formly-fund-select-tree/formly-fund-select-tree.component';
import { FormlyMaskInputComponent } from './formly-mask-input/formly-mask-input.component';
import { FormlyRepeatSectionComponent } from './formly-repeat-section/formly-repeat-section.component';
import { registerFormlyTranslateExtension } from './formly-translate.extension';
import { FormlyTextFieldnComponent } from './text-field';

@NgModule({
  declarations: [
    FormlyFundSelectTreeComponent,
    FormlyRepeatSectionComponent,
    FormlyMaskInputComponent,
    FormlyButtonComponent,
    FormlyFileUploadComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MaterialModule,
    TranslateModule,
    NgxMaskDirective,
    NgxMaskPipe,
    FormlyModule.forChild({
      types: [
        {
          // wrappers: ['form-field'],
          name: 'fund-select-tree',
          component: FormlyFundSelectTreeComponent,
        },
        {
          // wrappers: ['form-field'],
          name: 'repeat',
          component: FormlyRepeatSectionComponent,
        },
        {
          // wrappers: ['form-field'],
          name: 'text',
          component: FormlyTextFieldnComponent,
        },
        {
          wrappers: ['form-field'],
          name: 'mask-input',
          component: FormlyMaskInputComponent,
        },
        {
          name: 'button',
          component: FormlyButtonComponent,
        },
        {
          name: 'fileLogo',
          component: FormlyFileUploadComponent,
        },
      ],
      wrappers: [],
      extensions: [],
    }),
  ],
  exports: [ReactiveFormsModule, FormlyModule, NgxMaskDirective, NgxMaskPipe],
  providers: [
    {
      provide: FORMLY_CONFIG,
      multi: true,
      useFactory: registerFormlyTranslateExtension,
      deps: [TranslateService],
    },
  ],
})
export class FormlyExtensionModule {}
