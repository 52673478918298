import { Injectable, OnDestroy } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import { SubSink } from 'subsink';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

interface Ipaginator {
  itemPerPage: string;
  nextPage: string;
  prevoiusPage: string;
  firstPage: string;
  lastPage: string;
  rangeLagbel: string;
}
@Injectable()
export class CustomPaginatorIntl extends MatPaginatorIntl implements OnDestroy {
  private subs = new SubSink();
  rangeLabelIntl!: string;

  constructor(private translate: TranslateService) {
    super();
    this.getTranslations();
    this.subs.sink = this.translate.onLangChange.subscribe(() => {
      this.getTranslations();
    });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  getTranslations(): void {
    this.subs.sink = this.translate
      .get([
        'CUSTOM_PAGINATOR.LABEL.ITEM_PER_PAGE',
        'CUSTOM_PAGINATOR.LABEL.NEXT_PAGE',
        'CUSTOM_PAGINATOR.LABEL.PREVIOUS_PAGE',
        'CUSTOM_PAGINATOR.LABEL.FIRST_PAGE',
        'CUSTOM_PAGINATOR.LABEL.LAST_PAGE',
        'CUSTOM_PAGINATOR.LABEL.RANGE_LABE',
      ])
      .subscribe((translations: Ipaginator) => {
        this.itemsPerPageLabel = translations['itemPerPage'];
        this.nextPageLabel = translations['nextPage'];
        this.previousPageLabel = translations['prevoiusPage'];
        this.firstPageLabel = translations['firstPage'];
        this.lastPageLabel = translations['lastPage'];
        this.rangeLabelIntl = translations['rangeLagbel'];
        this.changes.next();
      });
  }

  override getRangeLabel = (page: number, pageSize: number, length: number) => {
    if (length === 0 || pageSize === 0) {
      return `0 ${this.rangeLabelIntl} ${length}`;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex =
      startIndex < length
        ? Math.min(startIndex + pageSize, length)
        : startIndex + pageSize;
    return `${startIndex + 1} - ${endIndex} ${this.rangeLabelIntl} ${length}`;
  };

  generateTranslateString() {
    _('CUSTOM_PAGINATOR.LABEL.ITEM_PER_PAGE');
    _('CUSTOM_PAGINATOR.LABEL.NEXT_PAGE');
    _('CUSTOM_PAGINATOR.LABEL.PREVIOUS_PAGE');
    _('CUSTOM_PAGINATOR.LABEL.FIRST_PAGE');
    _('CUSTOM_PAGINATOR.LABEL.LAST_PAGE');
    _('CUSTOM_PAGINATOR.LABEL.RANGE_LABE');
  }
}
