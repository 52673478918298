import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { DnnAutoLoginLafvSvc } from 'lafv-api';
import { map } from 'rxjs';
import { SubSink } from 'subsink';
import { OqtaneAuthService } from '../core/oqtane-auth.service';
import { LogTrace } from '../insights/insights.decorator';
import { NavigationItem } from '../models/interface';

@Component({
  selector: 'app-app-menu-list-item',
  templateUrl: './app-menu-list-item.component.html',
  styleUrls: ['./app-menu-list-item.component.scss'],
})
export class AppMenuListItemComponent implements OnInit, OnDestroy {
  @Input() item!: NavigationItem;
  private subs = new SubSink();
  isOpenMenu: boolean = false;

  sideNavItemsChild$ = this.oqtaneAuthService.getPermissions().pipe(
    map((permissions: any) => {
      var visibleItems = this.item.child?.filter((f) => {
        if (!f.permission) return true;
        return permissions[f.permission];
      });
      return visibleItems;
    })
  );

  constructor(
    private router: Router,
    private oqtaneAuthService: OqtaneAuthService,
    private dnnAutoLoginLafvSvc: DnnAutoLoginLafvSvc
  ) {



  }

  @LogTrace()
  ngOnInit(): void {
    if (this.item.child?.length) {
      this.subs.sink = this.router.events.subscribe((event: object) => {
        if (
          event instanceof NavigationEnd &&
          this.item?.child?.some((child) => {
            return (
              Array.isArray(child.link) &&
              child.link.includes(event.urlAfterRedirects.split('/')[1])
            );
          })
        ) {
          this.isOpenMenu = true;
        }
      });
    }
  }

  onItemClick() {
    this.isOpenMenu = !this.isOpenMenu;

    if (this.item.onClick != undefined) {
      this.item.onClick(this.dnnAutoLoginLafvSvc);
    }
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
