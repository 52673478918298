import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-formly-file-upload',
  templateUrl: './formly-file-upload.component.html',
  styleUrls: ['./formly-file-upload.component.scss']
})
export class FormlyFileUploadComponent extends FieldType {

  get frmControl(): FormControl {
    return this.formControl as FormControl;
  }

}
