import { Component, Input } from '@angular/core';
import { CardContent } from '../home.models';

@Component({
  selector: 'app-card-info',
  templateUrl: './card-info.component.html',
  styleUrls: ['./card-info.component.scss'],
})
export class CardInfoComponent {
  @Input() cardData!: CardContent;

  isLast(index: number): boolean {
    return index === this.cardData.viewInfo.length - 1;
  }
}
