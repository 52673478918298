import { versionInfo } from '../version-info';

export const environment = {
  name: 'Development',
  production: false,
  lafvApiUrl: '/lafv-api/',
  oqtaneApiUrl: '/',
  appVersion: versionInfo.semverString,
  appInsights: {
    applicationName: 'lafv-admin-dashboard',
    connectionString:
      'InstrumentationKey=c197e23e-4f4d-4606-a601-2f8b0eb9c9fe;IngestionEndpoint=https://westeurope-5.in.applicationinsights.azure.com/;LiveEndpoint=https://westeurope.livediagnostics.monitor.azure.com/',
  },
};
