<mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
  <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
    <button mat-icon-button disabled></button>
    <mat-checkbox
      color="primary"
      *ngIf="node.selectable"
      [checked]="fundSelectionModel.isSelected(node)"
      (change)="nodeSelectionToggle(node)"
    >
      {{ node.name }} ({{ node.isin }})
    </mat-checkbox>
    <span *ngIf="!node.selectable">
      {{ node.name }}
    </span>
  </mat-tree-node>
  <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
    <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name">
      <mat-icon class="mat-icon-rtl-mirror">
        {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
      </mat-icon>
    </button>
    {{ node.name }}
  </mat-tree-node>
</mat-tree>
