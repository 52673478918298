import { UiPermissions } from '../models/UiPermissions';

const allFalse: UiPermissions = {
  // System
  viewSystemPart: false,
  viewCurrencyRate: false,
  addCurrencyRate: false,
  editCurrencyRate: false,
  deleteCurrencyRate: false,
  viewUser: false,
  viewUserEditAdd: false,
  addUser: false,
  editUser: false,
  deleteUser: false,
  viewOrganization: false,
  addOrg: false,
  editOrg: false,
  deleteOrg: false,
  viewDataHistory: false,
  viewDropdDownValues: false,
  editDropdDownValues: false,
  addDropdDownValues: false,

  // Invoice
  viewInvoicePart: false,
  viewInvoiceList: false,
  viewInvoiceCreate: false,
  viewInvoiceControlling: false,
  viewInvoiceSettings: false,

  // umbrella
  viewUmbrella: false,
  addUmbrella: false,
  editUmbrella: false,
  deleteUmbrella: false,

  // fundheader
  viewEditAddFundheader: false,
  addFundHeader: false,
  editFundHeader: false,
  deleteFundHeader: false,
  viewDocumentFundHeader: false,
  viewFundHeader: false,

  // fund
  viewFundList: false,
  viewFund: false,
  viewEditAddFund: false,
  addFund: false,
  editFund: false,
  deleteFund: false,
  setFundAdvancedOptions: false,
  addFundFee: false,
  editFundFee: false,
  deleteFundFee: false,

  viewDataDocuments: false,

  // NAv
  viewNav: false,
  addFundNav: false,
  editFundNav: false,
  deleteFundNav: false,

  // Document
  viewDocument: false,
  historyDocument: false,
  viewUploadDocument: false,
  deleteDocument: false,
  // Upload History
  viewUploadHistory: false,

  // Fund TER/PTR
  viewTerPtr: false,
  addTerPtr: false,
  editTerPtr: false,
  viewTerPtrImport: false,

  // Fund Distribution
  viewFundDistribution: false,
  addDistribution: false,
  editDistribution: false,

  // Fund Nav
  viewFundNav: false,

  // Export
  viewExportPart: false,
  viewFmaExport: false,
  viewEfamaExport: false,
  viewFundsExport: false,
  createManualFmaExport: false,

  // Member Area
  viewMemberArea: false,
  viewMemberAreaTesting: false,
};

const LafvPassiveMember: UiPermissions = {
  ...allFalse,
  viewMemberArea: true,
};

const LafvFma: UiPermissions = {
  ...allFalse,
  viewExportPart: true,
  viewFmaExport: true,
  viewFundsExport: true,
};
// Special case, role only give in Frontend
// oqtane-auth.service.ts -> getPermissions()
const LafvFmaOrgAdmin: UiPermissions = {
  ...LafvFma,
  // System
  viewSystemPart: true,
  viewUser: true,
  viewUserEditAdd: true,
  addUser: true,
  editUser: true,
  deleteUser: true,
};

const LafvFundAdmins: UiPermissions = {
  ...allFalse,

  // Fundheader
  viewEditAddFundheader: true,
  addFundHeader: true,
  editFundHeader: true,
  deleteFundHeader: true,
  viewFundHeader: true,

  // Umbrella
  viewUmbrella: true,
  addUmbrella: true,
  editUmbrella: true,
  deleteUmbrella: true,

  // Fund
  viewFundList: true,
  viewFund: true,
  viewEditAddFund: true,
  addFund: true,
  editFund: true,
  deleteFund: true,

  // Fund Fee
  addFundFee: true,
  editFundFee: true,
  deleteFundFee: true,

  viewDataDocuments: true,

  // Fund NAV
  viewNav: true,
  addFundNav: true,
  editFundNav: true,
  deleteFundNav: true,

  // Document
  viewDocumentFundHeader: true,
  viewDocument: true,
  historyDocument: true,
  deleteDocument: true,
  viewUploadDocument: true,
  // Upload History
  editDistribution: false,

  // Fund TER/PTR
  viewTerPtr: true,
  addTerPtr: true,
  editTerPtr: true,
  viewTerPtrImport: true,

  // Fund Distribution
  viewFundDistribution: true,
  addDistribution: true,
  viewUploadHistory: true,

  // Fund Nav
  viewFundNav: true,

  // Export
  viewExportPart: true,
  viewFundsExport: true,

  // Member Area
  viewMemberArea: true,
};

const LafvOrgAdmins: UiPermissions = {
  ...LafvFundAdmins,

  // System
  viewSystemPart: true,
  viewUser: true,
  viewUserEditAdd: true,
  addUser: true,
  editUser: true,
  deleteUser: true,
};

const LafvAdmins: UiPermissions = {
  ...LafvOrgAdmins,

  // Fund
  setFundAdvancedOptions: true,

  // System
  viewCurrencyRate: true,
  addCurrencyRate: true,
  editCurrencyRate: true,
  deleteCurrencyRate: true,
  viewDataHistory: true,

  // Invoice
  viewInvoicePart: true,
  viewInvoiceList: true,
  viewInvoiceCreate: true,
  viewInvoiceControlling: true,
  viewInvoiceSettings: true,

  viewOrganization: true,
  addOrg: true,
  editOrg: true,
  deleteOrg: true,
  viewDropdDownValues: true,
  editDropdDownValues: true,
  addDropdDownValues: true,

  // Export
  viewFmaExport: true,
  viewEfamaExport: true,

  // Member Area
  viewMemberArea: false,
  viewMemberAreaTesting: true,
};

const SuperUser: UiPermissions = {
  ...LafvAdmins,
  createManualFmaExport: true,

};

export const defaultPermissionPerRole: Record<string, UiPermissions> = {
  LafvFundAdmins: LafvFundAdmins,
  LafvOrgAdmins: LafvOrgAdmins,
  LafvAdmins: LafvAdmins,
  SuperUser: SuperUser,
  LafvFma: LafvFma,
  LafvFmaOrgAdmin: LafvFmaOrgAdmin,
  LafvPassiveMember: LafvPassiveMember
};
