<ng-container *ngIf="userInfo$ | async; else showLogin">
  <mat-sidenav-container>
    <mat-sidenav #sidenav [mode]="smallScreen.matches ? 'over' : 'side'" [opened]="sideNavOpened">
      <!--  -->
      <div class="d-flex flex-column h-100">
        <!-- Mat-SideNave TOP with Logo  -->
        <div class="logo-position">
          <a class="logo-wrapper" routerLink="/">
            <div><img src="./assets/img/logo.svg" alt="Logo LAFV" /></div>
            <h2>Cockpit</h2>
          </a>
        </div>

        <!-- Component with all menu Items and Child Items from navigationItem-mockup -->
        <app-app-menu-list-item *ngFor="let item of sideNavItems$ | async" [item]="item"></app-app-menu-list-item>

        <div class="version-info mt-auto m-1 d-flex">
          <!-- Versions nr. -->
          <div *ngIf="backendEnvironment$ | async; let backendEnvironment">
            {{ backendEnvironment.frontendVersion }} /
            {{ backendEnvironment.backendVersion }}
          </div>
          <!-- Status (Local, Dev ...) -->
          <div class="ms-2" *ngIf="backendEnvironment$ | async; let backendEnvironment">
            <div
              *ngIf="backendEnvironment.environmentName !== 'Production'"
              class="container text-center rounded container-environment"
              [style.background-color]="backendEnvironment.backgroundColor"
              [style.color]="backendEnvironment.fontColor"
              [style.visibility]="!backendEnvironment.showInfoBar ? 'hidden' : 'visible'"
            >
              {{ backendEnvironment.environmentName }}
            </div>
          </div>
        </div>

      </div>

      <!-- 2md @ 06.12.2023 disabled pharse app, not needed atm -->
      <!-- <app-phrase-app-mode></app-phrase-app-mode> -->
    </mat-sidenav>

    <mat-sidenav-content>
      <!-- Toolbar -->
      <mat-toolbar>
        <button (click)="sidenav.opened = !sidenav.opened" mat-icon-button>
          <mat-icon>{{ sharedIcons.Menu }}</mat-icon>
        </button>

        <div class="breadcrumb-container">
          <xng-breadcrumb [separator]="emptyTemplate">
            <ng-container *xngBreadcrumbItem="let breadcrumb; let info = info; let last = last">
              <ng-container *ngIf="info === 'home' && last">
                <h2 style="margin: 0">
                  {{ 'HOME.TITLE.HOME' | translate }}
                </h2>
              </ng-container>
              <ng-container *ngIf="last">
                <h2 style="margin: 0">
                  {{ breadcrumb | translate }}
                </h2>
              </ng-container>
            </ng-container>
          </xng-breadcrumb>
          <ng-template #emptyTemplate></ng-template>
        </div>
        <span class="toolbar-spacer"></span>
        <!-- <div *ngIf="backendEnvironment$ | async; let backendEnvironment">
          <div
            *ngIf="backendEnvironment.environmentName !== 'Production'"
            class="container text-center rounded container-environment"
            [style.background-color]="backendEnvironment.backgroundColor"
            [style.color]="backendEnvironment.fontColor"
            [style.visibility]="!backendEnvironment.showInfoBar ? 'hidden' : 'visible'"
          >
            {{ backendEnvironment.environmentName }}
          </div>
        </div> -->

        <!-- User  -->
        <div class="user-container">
          <a mat-flat-button [matMenuTriggerFor]="menu" *ngIf="userInfo$ | async as userInfo">
            <div class="d-flex align-items-center">
              {{ userInfo.displayname }}
              <mat-icon>{{ sharedIcons.Expand_more }}</mat-icon>
            </div>
          </a>
        </div>
        <mat-menu #menu="matMenu">
          <!-- <button mat-menu-item>Settings</button> -->
          <button mat-menu-item (click)="openDialogChangePassword()">
            {{ 'ACCOUNT.CHANGE_PASSWORD.TITLE' | translate }}
          </button>
          <button mat-menu-item [routerLink]="[accountRouting.BaseName, accountRouting.Logout]">
            {{ 'ACCOUNT.LOGIN.LOGOUT_BTN.LABEL' | translate }}
          </button>
        </mat-menu>

        <!-- Languages -->
        <div class="btn-margin-container">
          <button (click)="useLanguage('en')" mat-flat-button [disabled]="currentLanguage === 'en'">EN</button>
          <button (click)="useLanguage('de')" mat-flat-button [disabled]="currentLanguage === 'de'">DE</button>
        </div>
      </mat-toolbar>

      <!-- Content components -->
      <div class="container-content">
        <div *ngIf="currentUrl !== '/' && currentUrl !== '/#'" class="breadcrumb">
          <app-breadcrumb></app-breadcrumb>
        </div>
        <router-outlet></router-outlet>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</ng-container>

<!-- If user not Login show only EN / DE and Login Page   -->
<ng-template #showLogin>
  <mat-toolbar>
    <div class="w-100 gap-3 d-flex justify-content-end">
      <button (click)="useLanguage('en')" mat-flat-button [disabled]="currentLanguage === 'en'">EN</button>
      <button (click)="useLanguage('de')" mat-flat-button [disabled]="currentLanguage === 'de'">DE</button>
    </div>
  </mat-toolbar>
  <router-outlet></router-outlet>
</ng-template>

<ngx-spinner bdColor="rgba(255,255,255,0.8)" size="large" color="#005EB8" type="ball-scale-multiple"></ngx-spinner>
